<template>
  <div class="wrapper u-flex-column u-col-center">
    <div class="u-flex" style="width: 100%;">
      <!-- <img class="logo" src="@/assets/images/QA/logo@2x.png" alt=""> -->
    </div>
    <img class="title" src="@/assets/images/QA/title.png" alt="">
    <div class="progress-box u-flex-column u-col-center">
      <div class="progress">
        <div class="bar" :style="{ width: percent }"></div>
      </div>
      <div class="txt u-m-t-20">飞速加载中......</div>
    </div>
    <!-- <img class="yun" src="@/assets/images/QA/yun.png" alt=""> -->
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      count: 0,
      percent: "0%",
    }
  },
  watch: {},
  computed: {},
  methods: {
    preload() {
      let imgs = [
        require('@/assets/images/QA/btn1.png'),
        require('@/assets/images/QA/correct.png'),
        require('@/assets/images/QA/determine-btn.png'),
        require('@/assets/images/QA/error.png'),
        require('@/assets/images/QA/fail.png'),
        require('@/assets/images/QA/home-bg.png'),
        require('@/assets/images/QA/home-title.png'),
        require('@/assets/images/QA/icon-correct.png'),
        require('@/assets/images/QA/icon-error.png'),
        require('@/assets/images/QA/know-btn.png'),
        require('@/assets/images/QA/next-btn.png'),
        require('@/assets/images/QA/sorry.png'),
        require('@/assets/images/QA/subject-box_01.png'),
        require('@/assets/images/QA/subject-box_02.png'),
        require('@/assets/images/QA/subject-box_03.png'),
        require('@/assets/images/QA/success.png'),
      ]
      for (let img of imgs) {
        let image = new Image();
        image.src = img;
        image.onload = () => {
          this.count++;
          // 计算图片加载的百分数，绑定到percent变量
          let percentNum = Math.floor((this.count / imgs.length) * 100);
          this.percent = Math.floor(percentNum) + "%";
          if (percentNum == 100) {
            setTimeout(() => {
              this.$router.replace({ path: "/QA/home" });
            }, 1000)
          }
        };
      }
    }
  },
  created() { },
  mounted() {
    this.preload()
  }
}
</script>
<style  lang="scss" scoped>
.wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url(../../../assets/images/QA/qaLoadingBg.png);
  background-size: 100% 100%;

  .logo {
    width: 316px;
    height: 40px;
    margin-left: 20px;
    margin-top: 60px;
  }

  .title {
    width: 663px;
    height: 296px;
    margin-top: 190px;
  }

  .yun {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 750px;
    height: 203px;
  }

  .progress-box {
    margin-top: 2rem;
    .txt {
      font-size: 36px;
      font-weight: normal;
      color: #f5b07c;
      margin-top: 40px;
    }

    .progress {
      width: 652px;
      height: 24px;
      background-color: #fff;
      border-radius: 20px;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 5px 5px;

      .bar {
        position: relative;
        width: 0%;
        height: 100%;
        background: #fe5400;
        border-radius: 9px;

        &::after {
          content: '';
          right: -38px;
          bottom: 0;
          position: absolute;
          width: 38px;
          height: 38px;
          // background-image: url(../../../assets/images/QA/icon-lun.png);
          // background-size: 100% 100%;
          // animation: rotation 3s linear infinite;
        }
      }


    }
  }

  @keyframes rotation {

    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }

  }
}
</style>